import { linkResolver } from 'prismic/linkResolver';
import { PrismicImage } from 'prismic/types/image';
import { PrismicRichText } from 'prismic/types/richtext';
import {
  PageSlicesOnboarding_Call_To_Action,
  PageSlicesOnboarding_Call_To_ActionDefaultItems,
} from 'prismic-types';

import { OnboardingCTA } from 'components/onboarding-cta/OnboardingCTA';
import { PrismicPicture } from 'components/picture/PrismicPicture';
import { asText, RichText } from 'components/rich-text/RichText';

import { ExcludesFalse } from 'utils/excludesFalse';
import { titleHeadingTag } from 'utils/pageTitleFromSlices';

export type OnboardingCallToActionSlice = PageSlicesOnboarding_Call_To_Action;
export type OnboardingCallToActionSliceItems =
  PageSlicesOnboarding_Call_To_ActionDefaultItems;

type Props = {
  title: PrismicRichText;
  text: PrismicRichText;
  image: PrismicImage;
  items: Array<OnboardingCallToActionSliceItems>;
};

export function OnboardingCallToAction({ title, text, image, items }: Props) {
  return (
    <OnboardingCTA
      title={asText(title)}
      image={image ? <PrismicPicture image={image} width={800} /> : undefined}
      headingType={titleHeadingTag(title)}
      links={items
        .filter(({ link, link_text }) => link && link_text)
        .map(({ link_text, link }) => {
          return {
            link: linkResolver(link),
            linkText: asText(link_text),
          };
        })}
    >
      <RichText>{text}</RichText>
    </OnboardingCTA>
  );
}

export function prismicSliceToOnboardingCallToAction(s: OnboardingCallToActionSlice) {
  const items = (s.variation?.items ?? []).filter(Boolean as unknown as ExcludesFalse);

  return (
    <OnboardingCallToAction
      title={(s.variation?.primary?.title ?? null) as PrismicRichText}
      text={(s.variation?.primary?.text ?? null) as PrismicRichText}
      image={(s.variation?.primary?.image ?? null) as PrismicImage}
      items={items}
    />
  );
}
