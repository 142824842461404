import { cloneElement } from 'react';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames/bind';

import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout';
import { H1 } from 'components/heading/Heading';
import { Link } from 'components/link/Link';
import { PictureProps } from 'components/picture/Picture';

import { HeadingTags } from 'types/html-types';

import s from './OnboardingCTA.module.scss';

const c = classnames.bind(s);

type Props = {
  title?: string;
  headingType?: HeadingTags;
  children?: React.ReactNode;
  image?: React.ReactElement<PictureProps>;
  links?: Array<{ link: string; linkText: string }>;
};

export const OnboardingCTA = ({
  title,
  headingType = 'h2',
  children,
  image,
  links,
}: Props) => {
  const { inView, ref } = useInView({ triggerOnce: true, threshold: 0.2 });
  const hasContent = title || children;

  if (!hasContent) {
    return null;
  }

  const contentColumns = { desktop: 5, wide: 5 };
  const contentOffset = {
    desktop: 7, //mediaPosition === 'left' ? 7 : undefined,
    wide: 7,
  };
  const mediaColumns = { tablet: 10, desktop: 6, wide: 6 };
  const mediaOffset = {
    tablet: 0,
    desktop: 0,
    wide: 0,
  };

  return (
    <div ref={ref}>
      <GridLayout
        className={c(s.onboardingCTA, {
          active: inView,
        })}
      >
        <GridLayoutItem
          columns={mediaColumns}
          offset={mediaOffset}
          className={c(s.cta__imageWrap)}
        >
          {image && cloneElement(image, { className: s.cta__media })}
        </GridLayoutItem>

        {hasContent && (
          <GridLayoutItem
            columns={contentColumns}
            offset={contentOffset}
            className={s.onboardingCTA__content}
          >
            {title && (
              <H1 type={headingType} className={s.onboardingCTA__title}>
                {title}
              </H1>
            )}

            {children && <div className={s.onboardingCTA__text}>{children}</div>}
            {links && links.length > 0 && (
              <div className={s.onboardingCTA__linksWrap}>
                {links.map(({ link, linkText }, i) => {
                  return (
                    <Link key={i} className={s.onboardingCTA__link} to={link}>
                      {linkText}
                    </Link>
                  );
                })}
              </div>
            )}
          </GridLayoutItem>
        )}
      </GridLayout>
    </div>
  );
};
