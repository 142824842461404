import cx from 'classnames'
import styles from './SliceContainer.module.scss'

interface SliceContainerProps {
  className?: string
  children?: React.ReactNode
  removeDefaultPadding?: boolean
}

const SliceContainer = (props: SliceContainerProps) => {
  const { className, children, removeDefaultPadding = false } = props
  return (
    <section
      className={cx(
        { [styles.defaultPadding]: !removeDefaultPadding },
        styles.sliceContainer,
        className
      )}
    >
      {children}
    </section>
  )
}

export default SliceContainer
