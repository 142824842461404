import React from 'react';
import classNames from 'classnames/bind';

import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout';
import { H2 } from 'components/heading/Heading';

import { HeadingTags } from 'types/html-types';

import s from './Chart.module.scss';

const c = classNames.bind(s);

type Props = {
  title?: string;
  description?: React.ReactElement;
  headingType?: HeadingTags;
  leading: React.ReactElement;
  column1: React.ReactElement;
  column2: React.ReactElement;
  column3: React.ReactElement;
  column4: React.ReactElement;
  column5: React.ReactElement;
  columnItems1?: Array<React.ReactElement>;
  columnItems2?: Array<React.ReactElement>;
  columnItems3?: Array<React.ReactElement>;
  columnItems4?: Array<React.ReactElement>;
  columnItems5?: Array<React.ReactElement>;
};

export const Chart = ({
  title,
  headingType = 'h2',
  description,
  leading,
  column1,
  column2,
  column3,
  column4,
  column5,
  columnItems1,
  columnItems2,
  columnItems3,
  columnItems4,
  columnItems5,
}: Props) => {
  return (
    <div className={s.chart}>
      <GridLayout>
        <GridLayoutItem offset={{ wide: 1 }}>
          <H2 type={headingType} className={s.chart__title}>
            {title}
          </H2>
          <div className={s.chart__description}>{description}</div>
        </GridLayoutItem>
      </GridLayout>
      <GridLayout className={s.chart__container}>
        <GridLayoutItem offset={{ wide: 4 }} columns={{ wide: 4 }}>
          {leading}
        </GridLayoutItem>
        <GridLayoutItem
          className={s.chart__line}
          offset={{ wide: 2 }}
          columns={{ wide: 8 }}
        />
      </GridLayout>
      <GridLayout>
        <GridLayoutItem
          className={c(s.chart__subContainer)}
          columns={{ wide: 2 }}
          offset={{ wide: 1 }}
        >
          {column1}
          <div className={s.chart__subList}>{columnItems1}</div>
        </GridLayoutItem>
        <GridLayoutItem
          className={c(s.chart__subContainer)}
          columns={{ wide: 2 }}
          offset={{ wide: 3 }}
        >
          {column2}
          <div className={s.chart__subList}>{columnItems2}</div>
        </GridLayoutItem>
        <GridLayoutItem
          className={c(s.chart__subContainer, { middle: true })}
          columns={{ wide: 2 }}
          offset={{ wide: 5 }}
        >
          {column3}
          <div className={s.chart__subList}>{columnItems3}</div>
        </GridLayoutItem>
        <GridLayoutItem
          className={c(s.chart__subContainer)}
          columns={{ wide: 2 }}
          offset={{ wide: 7 }}
        >
          {column4}
          <div className={s.chart__subList}>{columnItems4}</div>
        </GridLayoutItem>
        <GridLayoutItem
          className={c(s.chart__subContainer)}
          columns={{ wide: 2 }}
          offset={{ wide: 9 }}
        >
          {column5}
          <div className={s.chart__subList}>{columnItems5}</div>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
