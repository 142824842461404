import { query } from 'api/prismic'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { layoutQuery } from 'prismic/fragments/layoutQuery'
import {
  singleNavigationQuery,
  topNavigationQuery,
} from 'prismic/fragments/navigationQuery'
import { linkResolver } from 'prismic/linkResolver'
import { pageQuery } from 'prismic/queries/pageQuery'
import { PrismicLink } from 'prismic/types/link'
import { PrismicRichText } from 'prismic/types/richtext'
import {
  Charging_Station,
  Layout,
  Maybe,
  Navigation,
  Page,
} from 'prismic-types'

import { asText } from 'components/rich-text/RichText'
import { PrismicMeta } from 'containers/meta/PrismicMeta'
import { PageContainer } from 'containers/page/PageContainer'
import { PageSlices } from 'containers/slices/PageSlices'

import { PRISMIC_LOCALE, localeToPrismicLocale } from 'utils/i18n'
import { pageTitleFromSlices } from 'utils/pageTitleFromSlices'
import { ExcludesFalse } from 'utils/excludesFalse'
import { MapContainer } from 'containers/map/MapContainer'

export type PageProps = {
  preview: boolean
  page: Page | null
  layout: Layout | null
  uid?: string
  chargingStations?: Array<Charging_Station> | null
  navigation: (Maybe<Navigation> | undefined)[]
  onboardingButtonLink?: PrismicLink
}

export default function PageComponent(
  data: InferGetServerSidePropsType<typeof getServerSideProps>
) {
  const page = data.page ?? null

  if (!page) {
    return null
  }

  const pageTitle = asText(page.title as PrismicRichText)
  const title =
    pageTitle !== ''
      ? (page.title as PrismicRichText)
      : pageTitleFromSlices(page.slices ?? [])

  return (
    <>
      <PrismicMeta data={{ ...page, title }} layout={data.layout} />
      <PageContainer
        title={page.title}
        seo_title={page.seo_title}
        hero={page.hero}
        currentUid={page._meta.uid}
      />
      {data.chargingStations &&
        (data.uid === 'hledslustodvar' || data.uid === 'kort') && (
          <MapContainer
            chargingStations={data.chargingStations as Charging_Station[]}
            uid={data.uid}
          />
        )}
      <PageSlices slices={page.slices ?? []} />
    </>
  )
}
const chargingStationQuery = /* GraphQL */ `
  query chargingStations($lang: String = "${PRISMIC_LOCALE}") {
    allCharging_stations(lang: $lang, first: 100) {
      edges {
        node {
          title
          image
          address
          city
          isactive
          charginglist {
					  label
					  value
					}
          description
          county
          postcode
          location
          region {
            ...on Region {
              name
              image
              location
            }
          }
        }
      }
    }
  }
`

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  preview = false,
  previewData,
  params,
  resolvedUrl,
  locale,
}) => {
  const lang = localeToPrismicLocale(locale)
  const pagePath = params?.uid as string[]
  const uid = pagePath.slice(-1)[0]
  const variables = { uid, lang }
  const cacheKey = `${lang}-uid-${uid}`
  const isGoogleMapsPage = uid === 'hledslustodvar' || 'kort'

  // GraphQL hack for deep navigation
  const topNavData = await query(topNavigationQuery, { variables: { lang } })
  const topNav =
    topNavData?.allNavigations.edges
      ?.filter((edge) => edge && edge.node)
      .map((edge) => {
        return edge?.node._meta.uid
      }) ?? []

  const megamenuItems = async () => {
    return await Promise.all(
      topNav.map((uid) =>
        query(singleNavigationQuery, { variables: { lang, uid: uid! } })
      )
    )
  }

  const [navData, layoutData, pageData] = await Promise.all([
    megamenuItems(),
    query(layoutQuery, {
      variables: { lang },
    }),
    query(pageQuery, {
      previewData,
      variables,
      cacheKey: `page-${cacheKey}`,
      breadcrumbs: [resolvedUrl],
    }),
  ])

  const layout: Layout | null = layoutData?.layout ?? null
  const page: Page | null = pageData?.page ?? null
  const onboardingButtonLink = page?.onboarding_button_link ?? null
  const parentLink = page?.parent ? linkResolver(page.parent) : null
  const navigation = navData.map((navDataItem) => navDataItem?.navigation)

  const truePath = parentLink ? linkResolver(page, parentLink) : resolvedUrl

  if (isGoogleMapsPage) {
    const chargingStation = await Promise.all([
      query(chargingStationQuery, {
        variables: { lang },
        cacheKey: `charging-stations-${lang}`,
      }),
    ])
    const chargingStations =
      chargingStation[0]?.allCharging_stations.edges
        ?.flatMap((item) => item?.node)
        .filter(Boolean as unknown as ExcludesFalse) ?? null

    return {
      props: {
        preview,
        layout,
        navigation,
        page,
        chargingStations,
        uid,
      },
    }
  }

  if (!page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      preview,
      page,
      navigation,
      layout,
      onboardingButtonLink,
      uid,
    },
  }
}
