import { IconMap } from 'components/icon/Icon'

import s from './MapInfoWindow.module.scss'
import { Charging_StationCharginglist } from 'prismic-types'

type Props = {
  address?: JSX.Element
  chargingList?: Charging_StationCharginglist[] | null
  description?: string
  title?: string
}

export const MapInfoWindow = ({
  address,
  chargingList,
  description,
  title,
}: Props) => (
  <div className={s.container}>
    {title && <h1 className={s.title}>{title}</h1>}
    <div className={s.linkContainer}>
      <div className={s.links}>
        {address && address}
        {description && <p className={s.description}>{description}</p>}
      </div>
      <div className={s.iconContainer}>
        <div style={{ width: 12 }}>
          <IconMap />
        </div>
      </div>
    </div>
    <hr className={s.divider} />
    {chargingList && (
      <dl>
        {chargingList.map((item) => (
          <>
            <dt>{item.label}</dt>
            <dd>{item.value}</dd>
          </>
        ))}
      </dl>
    )}
  </div>
)
