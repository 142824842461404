import React from 'react';
import classNames from 'classnames/bind';

import s from './ChartItem.module.scss';

const c = classNames.bind(s);

export type ChartItemProps = {
  title?: string;
  content?: React.ReactNode;
  leading?: boolean;
  depthOne?: boolean;
  depthTwo?: boolean;
};

export const ChartItem = ({
  title,
  content,
  leading,
  depthOne,
  depthTwo,
}: ChartItemProps) => {
  if (!content) {
    return null;
  }

  return (
    <div className={c(s.chartItem, { leading, depthOne, depthTwo })}>
      <div className={s.chartItem__title}>{title}</div>
      <div className={s.chartItem__content}>{content}</div>
    </div>
  );
};
