import { AnimationControls, motion } from 'framer-motion'
import ArrowIcon from './Arrow.svg'
import Lightning from './LightningIcon'

import cx from 'classnames'

import transparentStyles from './TransparentButton.module.scss'
import lightningStyles from './LightningButton.module.scss'

import { arrowVariants } from './TransparentButton'
import { lightningRotationVariants } from './LightningButton'

interface FakeButtonProps {
  isHoverOnCard: boolean
  animationControls: AnimationControls
  isLightningButton: boolean
  titleOnButton?: string
}

const FakeButton = (props: FakeButtonProps) => {
  const { animationControls, isHoverOnCard, isLightningButton, titleOnButton } =
    props

  if (isLightningButton) {
    return (
      <div
        className={cx(lightningStyles.lightningButton, {
          [lightningStyles.buttonSmall]: true,
          [lightningStyles.cardIsOnHover]: isHoverOnCard,
        })}
      >
        <div className={lightningStyles.backgroundGradient} />
        <span className={lightningStyles.buttonTextLarge}>
          <motion.div
            animate={animationControls}
            variants={lightningRotationVariants}
            initial="initial"
            className={lightningStyles.lightningWrapper}
          >
            <Lightning
              className={lightningStyles.lightningIcon}
              strokeWidth={isHoverOnCard ? 2 : 3.5}
            />
          </motion.div>
          {titleOnButton ?? 'Velja'}
        </span>
      </div>
    )
  }

  return (
    <div
      className={cx(transparentStyles.transparentButton, {
        [transparentStyles.buttonSmall]: true,
        [transparentStyles.cardIsOnHover]: isHoverOnCard,
      })}
    >
      <div className={transparentStyles.backgroundGradient} />
      <span className={transparentStyles.buttonText}>
        {titleOnButton ?? 'Skoða'}
        <motion.div
          animate={animationControls}
          variants={arrowVariants}
          initial="initial"
          className={transparentStyles.arrowSpringWrapper}
        >
          <ArrowIcon className={transparentStyles.buttonArrowRight} />
        </motion.div>
      </span>
    </div>
  )
}

export default FakeButton
