import { LatLngLiteral } from 'google-maps-react-markers'
import classNames from 'classnames/bind'
import s from './MapMarker.module.scss'
import { IconMarker, IconMarkerActive } from 'components/icon/Icon'

interface MarkerProps {
  draggable: boolean
  isActive: boolean
  lat: number
  lng: number
  markerId: string
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    props: { lat: number; lng: number; markerId: string }
  ) => void
  onDrag?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    props: { latLng: LatLngLiteral }
  ) => void
  onDragEnd?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    props: { latLng: LatLngLiteral }
  ) => void
  onDragStart?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    props: { latLng: LatLngLiteral }
  ) => void
}

const IconShadow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 9">
    <g filter="url(#a)" opacity=".4">
      <ellipse cx="8" cy="4.5" fill="#320564" rx="5" ry="1.5" />
    </g>
    <defs>
      <filter
        id="a"
        width="16"
        height="9"
        x="0"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_5053_35755"
          stdDeviation="1.5"
        />
      </filter>
    </defs>
  </svg>
)

export const MapMarker = ({
  isActive,
  lat,
  lng,
  markerId,
  onClick,
  draggable,
  onDrag,
  onDragEnd,
  onDragStart,
  ...props
}: MarkerProps) => {
  const c = classNames.bind(s)

  return (
    <button
      className={c(s.button, isActive && s.active)}
      onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
      {...props}
    >
      {isActive ? (
        <div>
          <IconMarkerActive />
          <div className={s.shadow}>
            <IconShadow />
          </div>
        </div>
      ) : (
        <IconMarker />
      )}
    </button>
  )
}
