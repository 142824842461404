import { Maybe, PageSlicesStud_Svell_Embed, _Linkable } from 'prismic-types'
import { linkResolver } from 'prismic/linkResolver'
import SliceContainer from 'containers/slices/SliceContainer'
import IframeResizer from 'iframe-resizer-react'
import { useEffect, useRef, useState } from 'react'

/**
 * Props for `StudSvellEmbed`.
 */
export type StudSvellEmbedProps = {
  source?: Maybe<_Linkable>
}

/**
 * Component for "StudSvellEmbed" Slices.
 */
export const StudSvellEmbed = ({
  source,
}: StudSvellEmbedProps): JSX.Element => {
  const url = linkResolver(source)
  const iframeRef = useRef<HTMLDivElement>(null)

  const handleEvents = (e: any) => {
    if (e.origin === 'https://checkout.salescloud.is') {
      if (e.data === 'scrollIntoViewEvent') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleEvents)

    return () => {
      window.removeEventListener('message', handleEvents)
    }
  }, [])
  return (
    <SliceContainer>
      <div ref={iframeRef}>
        <IframeResizer
          style={{ width: '100%' }}
          src={url}
          checkOrigin={false}
          inPageLinks={true}
          heightCalculationMethod="taggedElement"
        />
      </div>
    </SliceContainer>
  )
}

export function prismicSliceToStudSvellEmbed(s: PageSlicesStud_Svell_Embed) {
  const source = s.variation?.primary?.source

  return <StudSvellEmbed source={source} />
}
