import { linkResolver } from 'prismic/linkResolver'
import { motion, useAnimationControls, Variants } from 'framer-motion'
import ArrowIcon from './Arrow.svg'

import { Link } from 'components/link/Link'
import cx from 'classnames'

import styles from './TransparentButton.module.scss'
import { SpringTransition } from './constants'
import { PrismicLinkWithMeta } from 'prismic/types/link'

export const arrowVariants: Variants = {
  hover: {
    width: 24,
    transition: SpringTransition,
  },
  initial: {
    width: 0,
    transition: SpringTransition,
  },
}
interface TransparentLinkProps {
  link: PrismicLinkWithMeta
  text: string
  isSmall?: boolean
}
const TransparentLink = (props: TransparentLinkProps) => {
  const { link, text, isSmall = false } = props
  const arrowSpringWidth = useAnimationControls()

  return (
    <Link
      to={linkResolver(link)}
      className={cx(styles.transparentButton, {
        [styles.buttonSmall]: isSmall,
      })}
      onMouseEnter={() => {
        arrowSpringWidth.start('hover')
      }}
      onMouseLeave={() => {
        arrowSpringWidth.start('initial')
      }}
    >
      <div className={styles.backgroundGradient} />
      <span className={styles.buttonText}>
        {text}
        <motion.div
          animate={arrowSpringWidth}
          variants={arrowVariants}
          initial="initial"
          className={styles.arrowSpringWrapper}
        >
          <ArrowIcon className={styles.buttonArrowRight} />
        </motion.div>
      </span>
    </Link>
  )
}

export default TransparentLink
