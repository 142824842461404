import classnames from 'classnames/bind'

import s from './Table.module.scss'
import { ChargingStation } from 'types/charing-types'

type TableProps = {
  data: ChargingStation[]
  title: string
  headers: string[]
  smallerTitle?: boolean
}

export const Table = ({ data, title, headers, smallerTitle }: TableProps) => {
  const tableHeaders =
    data[0] && (Object.keys(data[0]) as Array<keyof ChargingStation>)
  const c = classnames.bind(s)

  return (
    <div className={s.container}>
      <table className={s.table}>
        <caption className={c(s.caption, smallerTitle && s.smallerTitle)}>
          {title}
        </caption>
        <thead className={s.thead}>
          <tr>
            {headers?.map((header: string) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className={s.tbody}>
          {data.map((item, index) => (
            <tr key={index}>
              {tableHeaders.map((header) => (
                <td key={header}>{item[header] as string}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
