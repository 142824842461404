import React from 'react';
import { linkResolver } from 'prismic/linkResolver';
import { PrismicRichText } from 'prismic/types/richtext';
import { PageSlicesCard_List, PageSlicesCard_ListDefaultItems } from 'prismic-types';

import { ArticleCard } from 'components/article-card/ArticleCard';
import { CardListGrid } from 'components/card-list-grid/CardListGrid';
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture';
import { asText, RichText } from 'components/rich-text/RichText';
import { SectionHeader } from 'components/section-header/SectionHeader';

import { generateImageSizes } from 'utils/cleverCropping';
import { titleHeadingTag } from 'utils/pageTitleFromSlices';

type CardListItemItems = PageSlicesCard_ListDefaultItems;

type Props = {
  title?: PrismicRichText;
  description?: PrismicRichText;
  items: Array<CardListItemItems>;
};

export type CardListSlice = PageSlicesCard_List;

export const CardListSlice = ({ title, description, items }: Props) => {
  return (
    <CardListGrid
      header={
        <SectionHeader title={asText(title)} titleEl={titleHeadingTag(title)}>
          <RichText>{description}</RichText>
        </SectionHeader>
      }
    >
      {items
        .map((item, i) => (
          <ArticleCard
            key={i}
            title={asText(item.title_item)}
            link={
              item.media_link
                ? linkResolver(item.media_link)
                : item.link
                ? linkResolver(item.link)
                : ''
            }
            image={
              item.image ? (
                <RegularOrCroppableImage
                  image={item.image}
                  componentSizes={generateImageSizes({
                    ratio: 482 / 562,
                    wide: 562,
                  })}
                />
              ) : undefined
            }
          >
            <RichText>{item.description_item}</RichText>
          </ArticleCard>
        ))
        .filter(Boolean)}
    </CardListGrid>
  );
};

export const prismicSliceToCardList = (s: CardListSlice) => {
  const title = (s.variation?.primary?.title ?? null) as PrismicRichText;
  const description = (s.variation?.primary?.description ?? null) as PrismicRichText;
  const items = (s.variation?.items ?? []) as Array<CardListItemItems>;

  if (!items.length) {
    return null;
  }
  return <CardListSlice title={title} description={description} items={items} />;
};
