import { motion, useAnimationControls, Variants } from 'framer-motion'
import ArrowIcon from './Arrow.svg'

import cx from 'classnames'

import styles from './TransparentButton.module.scss'
import { SpringTransition } from './constants'

export const arrowVariants: Variants = {
  hover: {
    width: 24,
    transition: SpringTransition,
  },
  initial: {
    width: 0,
    transition: SpringTransition,
  },
}
interface TransparentButtonProps {
  onClick: () => void
  text: string
  isSmall?: boolean
}
const TransparentButton = (props: TransparentButtonProps) => {
  const { onClick, text, isSmall = false } = props
  const arrowSpringWidth = useAnimationControls()

  return (
    <button
      onClick={onClick}
      className={cx(styles.transparentButton, {
        [styles.buttonSmall]: isSmall,
      })}
      onMouseEnter={() => {
        arrowSpringWidth.start('hover')
      }}
      onMouseLeave={() => {
        arrowSpringWidth.start('initial')
      }}
    >
      <div className={styles.backgroundGradient} />
      <span className={styles.buttonText}>
        {text}
        <motion.div
          animate={arrowSpringWidth}
          variants={arrowVariants}
          initial="initial"
          className={styles.arrowSpringWrapper}
        >
          <ArrowIcon className={styles.buttonArrowRight} />
        </motion.div>
      </span>
    </button>
  )
}

export default TransparentButton
