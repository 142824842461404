import { RichText } from 'components/rich-text/RichText'
import { PowerPackageType } from 'pages/api/get-power-packages'

import { PrismicImage } from '@prismicio/react'

import cx from 'classnames'

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './PowerPackageCard.module.scss'
import LightningButton from 'components/new-buttons/LightningButton'
import SmallPowerPackageCard from './SmallPowerPackageCard'
import TransparentLink from 'components/new-buttons/TransparentLink'
import {
  formatPowerPackageTitle,
  formatPricesOnPackages,
  hasPackageTwoPrices,
} from 'utils/powerPackageHelper'
import { powerPackageDataUnit } from 'components/prices/PricesOfPackagesList'

interface PowerPackageCardProps {
  item: PowerPackageType
  isSmallSingleLine?: boolean
}

const PowerPackageCard = (props: PowerPackageCardProps) => {
  const { formatMessage } = useIntl()
  const { item, isSmallSingleLine = false } = props

  const shouldBeStrikedThrough = hasPackageTwoPrices(item.first_price_title)
  const [currentUid, setCurrentUid] = useState<string>('')

  useEffect(() => {
    setCurrentUid(getCurrentUid())
  }, [])

  const getCurrentUid = () => {
    const url = window.location.href
    const urlSplit = url.split('/')
    const currentUid = urlSplit[urlSplit.length - 1]
    return currentUid
  }

  const { preTitle, postTitle } = formatPowerPackageTitle(item.package_name)

  const onThisPackage = item._meta.uid.indexOf(currentUid) > -1

  if (isSmallSingleLine) {
    return (
      <SmallPowerPackageCard
        item={item}
        shouldBeStrikedThrough={shouldBeStrikedThrough}
        preTitle={preTitle}
        postTitle={postTitle}
        onThisPackage={onThisPackage}
      />
    )
  }

  return (
    <div className={styles.cardWrapper}>
      <div className={cx(styles.card, { [styles.greenBorder]: onThisPackage })}>
        <div className={styles.topWrapper}>
          <PrismicImage
            field={item.package_image}
            className={styles.packageImage}
          />

          <h3 className={styles.packageName}>
            <b>{preTitle}</b>
            {postTitle}
          </h3>
        </div>

        <div className={styles.contentWrapper}>
          <RichText className={styles.description}>{item.description}</RichText>

          <div className={styles.bottomWrapper}>
            <div className={styles.priceWrapper}>
              {!shouldBeStrikedThrough && (
                <>
                  <span
                    className={
                      shouldBeStrikedThrough
                        ? styles.firstPriceName
                        : styles.secondPriceName
                    }
                  >
                    {item.first_price_title}
                  </span>
                  <span
                    className={
                      shouldBeStrikedThrough
                        ? styles.firstPriceValue
                        : styles.secondPriceValue
                    }
                  >
                    {formatPricesOnPackages(item.package_first_price)}{' '}
                    {powerPackageDataUnit}
                  </span>
                </>
              )}

              <span className={styles.secondPriceName}>
                {item.second_price_title}
              </span>
              <span className={styles.secondPriceValue}>
                {formatPricesOnPackages(item.package_second_price)}{' '}
                {powerPackageDataUnit}
              </span>
            </div>
            <div className={styles.buttonWrapper}>
              <TransparentLink
                link={item.skoda}
                text={formatMessage({
                  id: 'view',
                  defaultMessage: 'Skoða',
                })}
              />
              <LightningButton
                link={item.velja}
                text={formatMessage({
                  id: 'sign_up',
                  defaultMessage: 'Velja',
                })}
                metaUid={item._meta.uid}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PowerPackageCard
