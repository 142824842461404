import { PrismicRichText } from 'prismic/types/richtext';
import { PageSlicesChart, PageSlicesChartDefaultItems } from 'prismic-types';

import { Chart } from 'components/chart/Chart';
import { ChartItem } from 'components/chart/ChartItem';
import { asText, RichText } from 'components/rich-text/RichText';

import { titleHeadingTag } from 'utils/pageTitleFromSlices';

type ListType = 'column1' | 'column2' | 'column3' | 'column4' | 'column5';

type ChartItemItems = PageSlicesChartDefaultItems;

type Props = {
  title?: PrismicRichText;
  description: PrismicRichText;
  leadingTitle: PrismicRichText;
  leadingContent: PrismicRichText;
  columnTitle1: PrismicRichText;
  columnContent1: PrismicRichText;
  columnTitle2: PrismicRichText;
  columnContent2: PrismicRichText;
  columnTitle3: PrismicRichText;
  columnContent3: PrismicRichText;
  columnTitle4: PrismicRichText;
  columnContent4: PrismicRichText;
  columnTitle5: PrismicRichText;
  columnContent5: PrismicRichText;
  items: Array<ChartItemItems>;
};

export type ChartSlice = PageSlicesChart;

export function ChartSlice({
  title,
  description,
  leadingTitle,
  leadingContent,
  columnTitle1,
  columnTitle2,
  columnTitle3,
  columnTitle4,
  columnTitle5,
  columnContent1,
  columnContent2,
  columnContent3,
  columnContent4,
  columnContent5,
  items,
}: Props) {
  // Columns
  const chartItem = (
    title: PrismicRichText,
    content: PrismicRichText,
    depthOne = true,
    leading?: boolean
  ) => {
    return (
      <ChartItem
        title={asText(title)}
        content={<RichText>{content}</RichText>}
        depthOne={depthOne}
        leading={leading}
      />
    );
  };

  // Filter items (set sub items under the correct column in the chart)
  const filterItems = (items: Array<PageSlicesChartDefaultItems>, type: ListType) => {
    return items
      .filter((item) => asText(item.title_item) && item.type === type)
      .map((item, i) => (
        <ChartItem
          key={i}
          title={asText(item.title_item)}
          content={<RichText>{item.content_item}</RichText>}
          depthTwo
        />
      ));
  };

  return (
    <Chart
      title={asText(title)}
      headingType={titleHeadingTag(title)}
      description={<RichText>{description}</RichText>}
      leading={chartItem(leadingTitle, leadingContent, false, true)}
      column1={chartItem(columnTitle1, columnContent1)}
      column2={chartItem(columnTitle2, columnContent2)}
      column3={chartItem(columnTitle3, columnContent3)}
      column4={chartItem(columnTitle4, columnContent4)}
      column5={chartItem(columnTitle5, columnContent5)}
      columnItems1={filterItems(items, 'column1')}
      columnItems2={filterItems(items, 'column2')}
      columnItems3={filterItems(items, 'column3')}
      columnItems4={filterItems(items, 'column4')}
      columnItems5={filterItems(items, 'column5')}
    />
  );
}

// eslint-disable-next-line complexity
export function prismicSliceToChart(s: ChartSlice) {
  // Single type
  const title = (s.variation?.primary?.title ?? null) as PrismicRichText;
  const description = (s.variation?.primary?.description ?? null) as PrismicRichText;
  const leadingTitle = (s.variation?.primary?.leading_title ?? null) as PrismicRichText;
  const leadingContent = (s.variation?.primary?.leading_content ??
    null) as PrismicRichText;
  const columnTitle1 = (s.variation?.primary?.column_title1 ?? null) as PrismicRichText;
  const columnContent1 = (s.variation?.primary?.column_content1 ??
    null) as PrismicRichText;
  const columnTitle2 = (s.variation?.primary?.column_title2 ?? null) as PrismicRichText;
  const columnContent2 = (s.variation?.primary?.column_content2 ??
    null) as PrismicRichText;
  const columnTitle3 = (s.variation?.primary?.column_title3 ?? null) as PrismicRichText;
  const columnContent3 = (s.variation?.primary?.column_content3 ??
    null) as PrismicRichText;
  const columnTitle4 = (s.variation?.primary?.column_title4 ?? null) as PrismicRichText;
  const columnContent4 = (s.variation?.primary?.column_content4 ??
    null) as PrismicRichText;
  const columnTitle5 = (s.variation?.primary?.column_title5 ?? null) as PrismicRichText;
  const columnContent5 = (s.variation?.primary?.column_content5 ??
    null) as PrismicRichText;

  // Repeatable type
  const items = (s.variation?.items ?? []) as Array<ChartItemItems>;

  if (!items.length) {
    return null;
  }

  return (
    <ChartSlice
      title={title}
      description={description}
      leadingTitle={leadingTitle}
      leadingContent={leadingContent}
      columnTitle1={columnTitle1}
      columnTitle2={columnTitle2}
      columnTitle3={columnTitle3}
      columnTitle4={columnTitle4}
      columnTitle5={columnTitle5}
      columnContent1={columnContent1}
      columnContent2={columnContent2}
      columnContent3={columnContent3}
      columnContent4={columnContent4}
      columnContent5={columnContent5}
      items={items}
    />
  );
}
