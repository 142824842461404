import { RichText } from 'components/rich-text/RichText'
import SliceContainer from 'containers/slices/SliceContainer'
import { PageSlicesPower_Package_Cta_Large } from 'prismic-types'
import { PrismicRichText } from 'prismic/types/richtext'

import styles from './PowerPackageCtaLarge.module.scss'
import PowerPackageCard from 'components/power-package-card/PowerPackageCard'
import { usePowerPackageData } from 'hooks/usePowerPackageData'

/**
 * Props for `PowerPackageCtaLarge`.
 */
export type PowerPackageCtaLargeProps = {
  title: PrismicRichText
  isSmallSingleLine?: boolean
}

/**
 * Component for "PowerPackageCtaLarge" Slices.
 */
export const PowerPackageCtaLarge = ({
  title,
  isSmallSingleLine = false,
}: PowerPackageCtaLargeProps): JSX.Element => {
  const { powerPackageData } = usePowerPackageData()

  return (
    <SliceContainer className={styles.powerPackageCtaLargeWrapper}>
      <RichText className={styles.title}>{title}</RichText>
      <div
        className={`${
          isSmallSingleLine
            ? styles.powerPackageCtaSingleLineTiles
            : styles.powerPackageCtaLargeTiles
        }`}
      >
        {powerPackageData?.allPower_packages.edges.map((edge, index) => {
          return (
            <PowerPackageCard
              key={`powerPackageCard-${index}`}
              item={edge.node}
              isSmallSingleLine={isSmallSingleLine}
            />
          )
        })}
      </div>
    </SliceContainer>
  )
}
export function prismicSliceToPowerPackageCtaLarge(
  s: PageSlicesPower_Package_Cta_Large
) {
  const title = s.variation?.primary?.title
  const isSmallSingleLine =
    s.variation?.__typename ===
    'PageSlicesPower_package_cta_largeSmallsingleline'

  return (
    <PowerPackageCtaLarge title={title} isSmallSingleLine={isSmallSingleLine} />
  )
}
