import { PowerPackageType } from 'pages/api/get-power-packages'

import { PrismicImage } from '@prismicio/react'

import { Link } from 'components/link/Link'
import { linkResolver } from 'prismic/linkResolver'

import cx from 'classnames'

import styles from './PowerPackageCard.module.scss'
import { useAnimationControls } from 'framer-motion'
import { useState } from 'react'
import FakeButton from 'components/new-buttons/FakeButton'
import { formatPricesOnPackages } from 'utils/powerPackageHelper'
import { powerPackageDataUnit } from 'components/prices/PricesOfPackagesList'

interface PowerPackageCardProps {
  item: PowerPackageType
  shouldBeStrikedThrough: boolean
  preTitle: string
  postTitle: string
  onThisPackage: boolean
}

const SmallPowerPackageCard = (props: PowerPackageCardProps) => {
  const { item, shouldBeStrikedThrough, preTitle, postTitle, onThisPackage } =
    props

  const linkTo = linkResolver(!onThisPackage ? item.skoda : item.velja)
  const animationControls = useAnimationControls()

  const [hover, setHover] = useState<boolean>(false)

  return (
    <Link
      to={linkTo}
      className={cx(styles.cardWrapper, styles.isSmallCardWrapper)}
      onMouseEnter={() => {
        animationControls.start('hover')
        setHover(true)
      }}
      onMouseLeave={() => {
        animationControls.start('initial')
        setHover(false)
      }}
    >
      <div className={cx(styles.card, { [styles.greenBorder]: onThisPackage })}>
        <div className={styles.topWrapper}>
          <PrismicImage
            field={item.package_image}
            className={styles.smallImage}
          />

          <h3 className={cx(styles.packageName, styles.packageNameSmall)}>
            <b>{preTitle}</b>
            {postTitle}
          </h3>
        </div>
        {onThisPackage && (
          <div className={styles.greenBanner}>Þú ert að skoða</div>
        )}
        <div className={cx(styles.contentWrapper, styles.contentWrapperSmall)}>
          <div className={styles.smallBottomWrapper}>
            <div className={styles.priceWrapper}>
              {!shouldBeStrikedThrough && (
                <>
                  <span
                    className={
                      shouldBeStrikedThrough
                        ? styles.firstPriceName
                        : styles.secondPriceName
                    }
                  >
                    {item.first_price_title}
                  </span>
                  <span
                    className={
                      shouldBeStrikedThrough
                        ? styles.firstPriceValue
                        : styles.secondPriceValue
                    }
                  >
                    {formatPricesOnPackages(item.package_first_price)}{' '}
                    {powerPackageDataUnit}
                  </span>
                </>
              )}

              <span className={styles.secondPriceName}>
                {item.second_price_title}
              </span>
              <span className={styles.secondPriceValue}>
                {formatPricesOnPackages(item.package_second_price)}{' '}
                {powerPackageDataUnit}
              </span>
            </div>
            <div className={styles.smallButtonWrapper}>
              <FakeButton
                animationControls={animationControls}
                isHoverOnCard={hover}
                isLightningButton={onThisPackage}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default SmallPowerPackageCard
