import { prismicSliceToAccordion } from 'prismic/slices/Accordion/AccordionSlice'
import {
  CallToActionSlice,
  prismicSliceToCallToAction,
} from 'prismic/slices/CallToAction/CallToActionSlice'
import { prismicSliceToCardList } from 'prismic/slices/CardList/CardListSlice'
import { prismicSliceToChart } from 'prismic/slices/Chart/ChartSlice'
import { prismicSliceToContentList } from 'prismic/slices/ContentList/ContentListSlice'
import { prismicSliceToCountDown } from 'prismic/slices/CountDown/CountDownSlice'
import { prismicSliceToGoogleSheetTableSlice } from 'prismic/slices/GoogleSheetTable/GoogleSheetTableSlice'
import { prismicSliceToGreenLight } from 'prismic/slices/GreenLight/GreenLightSlice'
import {
  NumbersSlice,
  prismicSliceToNumbers,
} from 'prismic/slices/Numbers/NumbersSlice'
import { prismicSliceToOnboardingCallToAction } from 'prismic/slices/OnboardingCallToAction/OnboardingCallToActionSlice'
import { prismicSliceToPrices } from 'prismic/slices/Prices/PricesSlice'
import { prismicSliceToStaffList } from 'prismic/slices/StaffList/StaffListSlice'
import { prismicSliceToText } from 'prismic/slices/Text/TextSlice'
import {
  PageSlices,
  PageSlicesAccordion,
  PageSlicesCard_List,
  PageSlicesChart,
  PageSlicesContent_List,
  PageSlicesCount_Down,
  PageSlicesGoogle_Sheet_Table,
  PageSlicesGreen_Light,
  PageSlicesOnboarding_Call_To_Action,
  PageSlicesPower_Package_Cta_Large,
  PageSlicesPrices,
  PageSlicesStaff_List,
  PageSlicesStud_Svell_Embed,
  PageSlicesText,
} from 'prismic-types'

import { ArticleSection } from 'components/article-section/ArticleSection'
import { Section } from 'components/section/Section'
import { prismicSliceToPowerPackageCtaLarge } from 'prismic/slices/PowerPackageCtaLarge/index'
import { prismicSliceToStudSvellEmbed } from 'prismic/slices/StudSvellEmbed'

type Props = {
  slices?: Array<PageSlices>
}

export function PageSlices({ slices = [] }: Props) {
  return (
    <>
      {slices.map((s: PageSlices, i: number) => {
        const key = `slice-${s.type}-${i}`

        switch (s.type) {
          case 'card_list':
            return (
              <Section key={key}>
                {prismicSliceToCardList(s as PageSlicesCard_List)}
              </Section>
            )
          case 'chart':
            return (
              <Section key={key}>
                {prismicSliceToChart(s as PageSlicesChart)}
              </Section>
            )
          case 'staff_list':
            return (
              <Section key={key}>
                {prismicSliceToStaffList(s as PageSlicesStaff_List)}
              </Section>
            )
          case 'prices':
            return (
              <Section key={key}>
                {prismicSliceToPrices(s as PageSlicesPrices)}
              </Section>
            )
          case 'green_light':
            return (
              <Section key={key}>
                {prismicSliceToGreenLight(s as PageSlicesGreen_Light)}
              </Section>
            )
          case 'call_to_action': {
            return (
              <Section key={key}>
                {prismicSliceToCallToAction(s as CallToActionSlice)}
              </Section>
            )
          }
          case 'numbers':
            return (
              <Section key={key}>
                {prismicSliceToNumbers(s as NumbersSlice)}
              </Section>
            )
          case 'google_sheet_table':
            return (
              <ArticleSection key={key}>
                {prismicSliceToGoogleSheetTableSlice(
                  s as PageSlicesGoogle_Sheet_Table
                )}
              </ArticleSection>
            )
          case 'accordion':
            return (
              <ArticleSection key={key}>
                {prismicSliceToAccordion(s as PageSlicesAccordion)}
              </ArticleSection>
            )
          case 'count_down':
            return (
              <Section key={key} container>
                {prismicSliceToCountDown(s as PageSlicesCount_Down)}
              </Section>
            )
          case 'content_list':
            return (
              <Section key={key}>
                {prismicSliceToContentList(s as PageSlicesContent_List)}
              </Section>
            )
          case 'text':
            return (
              <ArticleSection key={key}>
                {prismicSliceToText(s as PageSlicesText)}
              </ArticleSection>
            )
          case 'onboarding_call_to_action':
            return (
              <Section key={key}>
                {prismicSliceToOnboardingCallToAction(
                  s as PageSlicesOnboarding_Call_To_Action
                )}
              </Section>
            )
          case 'power_package_cta_large':
            return (
              <Section key={key}>
                {prismicSliceToPowerPackageCtaLarge(
                  s as PageSlicesPower_Package_Cta_Large
                )}
              </Section>
            )
          case 'stud_svell_embed':
            return (
              <Section key={key}>
                {prismicSliceToStudSvellEmbed(s as PageSlicesStud_Svell_Embed)}
              </Section>
            )
          default:
            console.info(`unknown slice type ${s.type}`, s)
            return <Section key={key}>{`unknown slice type ${s.type}`}</Section>
        }
      })}
    </>
  )
}
