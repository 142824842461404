/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from 'react'
import GoogleMap from 'google-maps-react-markers'
import classNames from 'classnames/bind'
import { Charging_Station } from 'prismic-types'
import { useIntl } from 'react-intl'
import Link from 'next/link'

import { useResize } from 'hooks/useResize'

import { MapMarker } from 'components/map-marker/MapMarker'
import { MapInfoWindow } from 'components/map-info-window/MapInfoWindow'

import { mapStyles } from '../../../data/MapStyles'
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'
import { Table } from 'components/table/Table'
import { ChargingStation } from 'types/charing-types'

import s from './Mapcontainer.module.scss'
import { asText } from 'components/rich-text/RichText'

type Props = {
  chargingStations: Charging_Station[]
  uid?: string
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    lat?: number
    lng?: number
  }
}

const c = classNames.bind(s)

const processAndSplitData = (data: Charging_Station[] | null) => {
  const activeArray: ChargingStation[] = []
  const inactiveArray: ChargingStation[] = []
  const googleMapArray: ChargingStation[] = []

  data?.forEach((item) => {
    const processedItem: ChargingStation = {
      title: asText(item.title),
      address:
        item.location &&
        (item.address && item.city ? (
          <Link
            href={`https://www.google.com/maps/dir/?api=1&destination=${item.location.latitude},${item.location.longitude}&destination_place_id=${item.address[0].text}&travelmode=driving&dir_action=navigate`}
            target="_blank"
            rel="noreferrer"
          >
            {asText(item.address) +
              ', ' +
              asText(item.postcode) +
              ' ' +
              asText(item.city)}
          </Link>
        ) : (
          ''
        )),
      description: item.description || '',
      charging:
        item.charginglist
          ?.map((chargingStation) => {
            if (chargingStation.label && chargingStation.value)
              return chargingStation.label + ' – ' + chargingStation.value
          })
          .join(', ') || '',
    }

    if (item.isactive) {
      activeArray.push(processedItem)
      const processedItemMaps: ChargingStation = {
        ...processedItem,
        chargingList: item.charginglist,
        latitude: item.location.latitude,
        longitude: item.location.longitude,
      }
      googleMapArray.push(processedItemMaps)
    } else {
      inactiveArray.push(processedItem)
    }
  })

  return { activeArray, inactiveArray, googleMapArray }
}

export const MapContainer = ({ chargingStations, uid }: Props) => {
  const { activeArray, inactiveArray, googleMapArray } = processAndSplitData(
    chargingStations || []
  ) as unknown as {
    activeArray: ChargingStation[]
    inactiveArray: ChargingStation[]
    googleMapArray: ChargingStation[]
  }
  const intl = useIntl()
  const isPageWithTable = uid === 'kort'

  const tableHeaders = [
    intl.formatMessage({ id: 'charging_station_table_heading_1' }),
    intl.formatMessage({ id: 'charging_station_table_heading_2' }),
    intl.formatMessage({ id: 'charging_station_table_heading_3' }),
    intl.formatMessage({ id: 'charging_station_table_heading_4' }),
  ]
  const [activeMarker, setActiveMarker] = useState<number | null>(null)
  const { isMobile } = useResize()
  const mapRef = useRef<google.maps.Map | null>(null)

  const onGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    mapRef.current = map
  }

  const onMarkerClick = ({ lat, lng }: { lat: number; lng: number }) => {
    mapRef?.current?.setCenter({ lat, lng })
  }

  return (
    <>
      <div
        className={c(
          isPageWithTable ? s.googlemapsWithTable : s.googlemapsContainer
        )}
      >
        {!isPageWithTable && (
          <GridLayout>
            <GridLayoutItem columns={{ desktop: 6 }} className={s.textWrapper}>
              <h2 className={s.title}>Hleðslustöðvar um landið</h2>
              <p className={s.description}>
                Hleðslustöðvar Orkusölunnar má finna víðsvegar um landið. Hér á
                vefnum má finna yfirlit yfir núverandi og væntanlegar stöðvar.
              </p>
              <Link href="/hledslustodvar/kort">Listi yfir hleðslustöðvar</Link>
            </GridLayoutItem>
          </GridLayout>
        )}
        <GridLayout>
          <GridLayoutItem
            columns={{ wide: 12 }}
            offset={{ wide: 0 }}
            className={s.map}
          >
            <GoogleMap
              apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || ''}
              mapMinHeight="70vh"
              defaultZoom={isMobile ? 5 : 7}
              defaultCenter={{ lat: 64.9631, lng: -19.0208 }}
              onGoogleApiLoaded={onGoogleApiLoaded}
              options={{
                styles: mapStyles,
                disableDefaultUI: true,
                zoomControl: true,
                rotateControl: true,
                mapTypeControl: false,
              }}
            >
              {googleMapArray?.map((item, i) =>
                item.latitude && item.longitude && item.address ? (
                  <MapMarker
                    markerId={item.title as string}
                    key={item.title}
                    lat={item.latitude}
                    lng={item.longitude}
                    isActive={activeMarker === i}
                    onClick={(ev) => {
                      setActiveMarker(i)
                    }}
                    draggable={false}
                  ></MapMarker>
                ) : null
              )}
              {googleMapArray?.map(
                (item, i) =>
                  activeMarker === i && (
                    <div
                      lat={item.latitude}
                      lng={item.longitude}
                      key={item.description}
                      style={{
                        position: 'absolute',
                        top: 'calc(100% - 24px)',
                        left: '50%',
                        transform: 'translate(-50%, -100%)',
                      }}
                    >
                      <MapInfoWindow
                        title={item.title}
                        address={item.address}
                        description={item.description}
                        chargingList={item.chargingList}
                      />
                    </div>
                  )
              )}
            </GoogleMap>
          </GridLayoutItem>
        </GridLayout>
      </div>
      {isPageWithTable && (
        <GridLayout>
          <GridLayoutItem columns={{ wide: 12 }}>
            {activeArray && (
              <Table
                data={activeArray}
                title={intl.formatMessage({
                  id: 'charging_station_table_title',
                })}
                headers={tableHeaders}
              />
            )}
            {inactiveArray && (
              <Table
                data={inactiveArray}
                title={intl.formatMessage({
                  id: 'charging_station_table__disabled_title',
                })}
                headers={tableHeaders}
                smallerTitle
              />
            )}
          </GridLayoutItem>
        </GridLayout>
      )}
    </>
  )
}
